




















import './styles/MemberPopups.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const NSProject = namespace('storeProject');

@Component({
    name: 'MemberPopups',

    components: {
        PopupEditProjectMember: () => import('@components/popups/PopupEditProjectMember.vue'),
        PopupConfirmation: () => import('@components/popups/PopupConfirmation.vue'),
        BasePreloader: () => import('@components/BasePreloader/BasePreloader.vue'),
    },
})
export default class MemberPopups extends Vue {
    $refs!: {
        memberEditPopup: HTMLFormElement,
    }

    @Prop({}) selectedItem!: any;
    @Prop({}) selectedUserName!: string;
    @Prop({}) showPreloader!: boolean;

    confirmationPopupOpen: boolean = false;

    openMemberEditPopup() {
        this.$refs.memberEditPopup.openPopup();
    }

    showConfirmPopup() {
        // !!! Не удалять метод! Он вызывается извне !!!
        this.confirmationPopupOpen = true;
    }

    confirmUserDisable() {
        this.$emit('confirm-user-disable');
    }

    closePopup() {
        this.confirmationPopupOpen = false;
        this.$refs.memberEditPopup.closePopup();
    }
}
